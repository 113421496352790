import React, { useState } from 'react';
import awsConfig from '../../awsconfig';
import { Backup } from '@mui/icons-material';
import {
  Button,
  Typography,
  Grid,
  Box,
  Card,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { getUserToken, getUploadPath } from '../../utils/auth';
import Layout from '../../layout/index';
import colors from '../../theme/colors.json';
import { FileUpload } from './file-upload';
import { useSnackbar } from 'notistack';

interface PresignedUrlResponse {
  partUrls: {
    url: string;
    number: number;
  }[];
  uploadId: string;
  filetype: string;
  name: string;
}

export default function Dashboard(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<FileList | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);

  let sharedPath = '';

  async function completeUpload(fileUpload: FileUpload): Promise<void> {
    const parts = fileUpload.parts
      .map((part) => ({ PartNumber: part.partNumber, ETag: part.etag }))
      .sort((p, n) => p.PartNumber - n.PartNumber);

    const ok = await fetch(`${awsConfig.api_url}/complete-multipart`, {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + (await getUserToken()) },
      body: JSON.stringify({
        filename: fileUpload.fileName,
        uploadId: fileUpload.uploadId,
        parts,
        path: fileUpload.path,
      }),
    }).then((res) => {
      if (res.status === 200) {
        fileUpload.completeUpload().then(() => {
          setUploaded(true);
          enqueueSnackbar(`Upload of file '${fileUpload.fileName}' completed!`, {
            variant: 'success',
          });
        });
        return true;
      } else {
        return false;
      }
    });

    if (!ok) {
      enqueueSnackbar(`Error completing upload of file '${fileUpload.fileName}' `, {
        variant: 'error',
      });
      setErrorMessage('Error uploading file');
    }
  }

  async function signedURLUpload(fileUpload: FileUpload): Promise<void> {
    // Check localStorage to see if we can resume a previously started upload
    await fileUpload.checkResume();

    let params = {};
    if (!fileUpload.uploadId) {
      params = {
        filename: fileUpload.fileName,
        filetype: fileUpload.fileType,
        totalParts: fileUpload.parts.length,
      };
      fileUpload.path = sharedPath;
    } else {
      if (!fileUpload.incompleteParts.length) {
        await completeUpload(fileUpload);
        return;
      }

      params = {
        uploadId: fileUpload.uploadId,
        filename: fileUpload.fileName,
        filetype: fileUpload.fileType,
        parts: fileUpload.incompleteParts.map((p) => p.partNumber),
      };
    }

    const generateUrl = new URL(`${awsConfig.api_url}/generate-presigned-urls`);
    generateUrl.search = new URLSearchParams(params).toString();

    const response: PresignedUrlResponse = await fetch(generateUrl.toString(), {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + (await getUserToken()) },
      body: JSON.stringify({ path: fileUpload.path }),
    })
      .then((res) => (res.status === 200 ? res.json() : null))
      .catch(() => setTimeout(() => signedURLUpload(fileUpload), 10000));

    // Fetch ends up here with a response as an int when a network interruption occurs, the catch above will retry
    // this is here to stop this execution path
    if (!response || !response.partUrls) {
      return;
    }

    if (!fileUpload.uploadId) {
      fileUpload.uploadId = response.uploadId;
      await fileUpload.saveToFileUploadCache();
    }

    console.log('UploadId: ' + fileUpload.uploadId);
    const parts = response.partUrls;

    // Make a request to upload a part using the given signedUrl
    // Once request is complete, pull the next part and upload it
    // return once there are no more parts waiting to be uploaded
    async function uploadPart(number: number, url: string): Promise<void> {
      return fetch(url, {
        method: 'PUT',
        body: fileUpload.getFilePartSlice(number),
      })
        .then(async (res) => {
          if (res.status === 200 && res.headers.get('ETag')) {
            console.log(
              'Completing #' +
                number +
                ' ETAG: ' +
                res.headers.get('ETag') +
                ' Parts remaining: ' +
                fileUpload.incompleteParts.length,
            );
            await fileUpload.completePart(number, res.headers.get('ETag') || '');
          }
        })
        .catch((e) => console.log(`Failure for part #${number} : ${e}`))
        .finally(() => {
          const nextPart = parts.shift();
          if (nextPart) {
            return uploadPart(nextPart?.number, nextPart?.url);
          } else {
            return;
          }
        });
    }

    // Create 10 'threads' to ensure that we have exactly 10 part upload requests in flight at any given time
    const threads = parts.splice(0, 10);
    await Promise.all(threads.map(async ({ number, url }) => uploadPart(number, url)));

    if (fileUpload.incompleteParts.length) {
      console.log(
        `Re-attempting upload of file '${fileUpload.fileName}'. ${fileUpload.incompleteParts.length} incomplete parts remain`,
      );
      return signedURLUpload(fileUpload);
    }

    await completeUpload(fileUpload);
  }

  const resetUpload = () => {
    if (!errorMessage) {
      setFiles(null);
    }
    setLoading(false);
  };

  async function upload() {
    const currFiles = files;
    if (!(currFiles && currFiles.length)) {
      return;
    }
    setLoading(true);
    setUploaded(false);
    setFileUploads([]);

    const filesList = Array.from(currFiles).map((file: File) => new FileUpload(file));

    setFileUploads(filesList);

    filesList.forEach((file) => (file.onPartsUpdate = () => setFileUploads([...filesList])));

    sharedPath = await getUploadPath();

    await Promise.all(filesList.map(async (fileUpload) => signedURLUpload(fileUpload)));

    resetUpload();
  }

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!event || !event.target || !event.target.files) {
      return;
    }

    setFiles(event.target.files);
  }

  return (
    <Layout>
      <>
        <Grid container justifyContent="center" mt={8}>
          <Card style={{ margin: 'auto', width: '50%', padding: 50 }}>
            <Box display="flex" justifyContent="center">
              {loading ? (
                <Skeleton animation="wave" variant="circular" width={100} height={100} />
              ) : (
                <Backup style={{ fontSize: 150 }} />
              )}
            </Box>
            <Box display="flex" justifyContent="center" p={1 / 2}>
              {loading ? (
                <Skeleton animation="wave" variant="text" width={300} height={40} />
              ) : (
                <Typography variant="h1">Upload your file</Typography>
              )}
            </Box>

            <Box display="flex" justifyContent="center" p={1 / 2}>
              <Typography variant="subtitle2" style={{ color: colors.red }}>
                Notice: This system is not approved for uploading classified data.
              </Typography>
            </Box>
            <Box>
              <List>
                {fileUploads.map((u, i) => (
                  <ListItem key={i}>
                    <ListItemText>
                      <Typography>{u.fileName}</Typography>
                      <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <LinearProgress
                            color={u.progress === 100 ? 'primary' : 'secondary'}
                            variant="determinate"
                            value={u.progress}
                          />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">
                            {u.progress}%
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box display="flex" justifyContent="center" p={1 / 2} mt={3}>
              {loading ? (
                <Skeleton animation="wave" variant="text" width={300} height={40} />
              ) : (
                <input type="file" name="file" multiple onChange={onChangeHandler} />
              )}
            </Box>
            <Box display="flex" justifyContent="center" p={1 / 2} mt={3}>
              <Typography variant="subtitle2">The file(s) should be in an archive Zip file for upload.</Typography>
            </Box>
            <Box display="flex" justifyContent="center" p={1 / 2} mt={3}>
              <Typography variant="subtitle2">
                If you experience any issues or interruption when uploading, if the browser is refreshed or closed,
                uploads may be resumed by uploading the same file once again and progress should resume.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" p={1 / 2} mt={3}>
              {loading ? (
                <Skeleton animation="wave" variant="rectangular" width={300} height={60} />
              ) : (
                <Tooltip title={!files ? 'No file chosen' : ''}>
                  <div style={{ width: '100%' }}>
                    <Button style={{ width: '100%' }} color="primary" onClick={upload} disabled={!files}>
                      Upload
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Box>
            <Box display="flex" justifyContent="center" p={1 / 2} mt={3}>
              {errorMessage && (
                <Typography style={{ color: colors.red }} className="error-message">
                  {errorMessage}
                </Typography>
              )}
              {!files && uploaded && (
                <Typography style={{ color: colors.accessibleGreen }}>Upload successful, thank you.</Typography>
              )}
            </Box>
          </Card>
        </Grid>
      </>
    </Layout>
  );
}
