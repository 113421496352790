export interface AWSConfig {
  api_url: string;
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_identity_pool_id: string;
  bucket: string;
}

const awsConfig: AWSConfig = {
  api_url: 'https://hsx5hhh04m.execute-api.us-gov-west-1.amazonaws.com',
  aws_project_region: 'us-gov-west-1',
  aws_cognito_region: 'us-gov-west-1',
  aws_user_pools_id: 'us-gov-west-1_52sdEMQWH',
  aws_user_pools_web_client_id: 'nm6trcgenand0jbeoimqbgfm4',
  aws_identity_pool_id: 'us-gov-west-1:24470864-9661-48e3-87eb-fdf78c983c2f',
  bucket: 'sadel-bucket-bubx0ylcegegaujx',
};

export default awsConfig;
