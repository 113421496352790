import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, IconButton, MenuItem, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import Logo from '../assets/deloitte logo.png';

const PREFIX = 'navbar';

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    paddingBottom: 10,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
}));

export default function NavBar(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <img style={{ width: 150, padding: 5, marginRight: 75 }} src={Logo} alt="" />

          <Typography variant="h6" className={classes.title}>
            Safe Delivery Portal
          </Typography>

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              size="large"
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => Auth.signOut()}>Sign Out</MenuItem>
              <MenuItem component="button" id="ot-sdk-btn" className="optanon-toggle-display">
                Cookie Settings
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Root>
  );
}
