/* For more info on Deloitte's styling guides: https://brandspace.deloitte.com/content/index/guid/web_style_guide?parent=211 */
import PropTypes, { InferProps } from 'prop-types';
import { useMemo } from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useAppContext from '../app-context';
import colors from './colors.json';
import Zoom from '@mui/material/Zoom';
import chroma from 'chroma-js';
import { PaletteMode } from '@mui/material';

const getTheme = (themeType: PaletteMode) => {
  const defaultTheme = createTheme();

  return createTheme({
    palette: {
      mode: themeType,
      common: {
        black: colors.black,
        white: colors.white,
      },
      primary: {
        light: colors.green2,
        main: colors.accessibleGreen,
        dark: colors.green7,
      },
      secondary: {
        light: colors.blue2,
        main: colors.accessibleBlue,
        dark: colors.blue7,
      },
      error: {
        light: chroma(colors.red).alpha(0.35).hex(),
        main: colors.red,
        dark: chroma(colors.red).darken().hex(),
      },
      warning: {
        light: chroma(colors.orange).alpha(0.35).hex(),
        main: colors.orange,
        dark: chroma(colors.orange).darken().hex(),
      },
      info: {
        light: colors.teal1,
        main: colors.accessibleTeal,
        dark: colors.teal7,
      },
      success: {
        light: colors.green4,
        main: colors.deloitteGreen,
        dark: colors.green6,
      },
    },
    typography: {
      htmlFontSize: 12,
      fontSize: 12,
      fontFamily: "'Noto Sans', sans-serif",
      // headline
      h1: {
        fontSize: '26px',
        fontWeight: 'normal',
        lineHeight: '30px',
      },
      // subtitle
      h2: {
        fontSize: '20px',
        fontWeight: 'normal',
        lineHeight: '26px',
      },
      // page intro
      h3: {
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '22px',
      },
      // component heading
      h4: {
        fontSize: '20px',
        fontWeight: 'normal',
        lineHeight: '24px',
      },
      // component subtitle
      subtitle1: {
        fontSize: '15px',
        fontWeight: 'bold',
        lineHeight: '19px',
      },
      // component subtitle2
      subtitle2: {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '16px',
      },
      // body copy
      body1: {
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '16px',
      },
      // small copy
      body2: {
        fontSize: '11px',
        fontWeight: 'normal',
        lineHeight: '15px',
      },
      // caption
      caption: {
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'italic',
        lineHeight: '15px',
      },
      // button small
      button: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {},
        },
        defaultProps: {
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            subtitle1: 'h6',
            subtitle2: 'h6',
            body1: 'span',
            body2: 'span',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: "'Noto Sans', sans-serif",
            fontSize: '12px',
            lineHeight: '16px',
          },
          a: {
            color: colors.accessibleGreen,
            textDecoration: 'none',
          },
          '*::-webkit-scrollbar': {
            width: '5px',
            height: '8px',
            backgroundColor: defaultTheme.palette.grey[100],
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: defaultTheme.palette.grey[600],
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
        defaultProps: {
          size: 'small',
          color: 'primary',
        },
      },
      MuiSwitch: {
        defaultProps: {
          size: 'small',
          color: 'primary',
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
        defaultProps: {
          size: 'small',
          color: 'primary',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: 'small',
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            height: 'fit-content',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'none',
          },
        },
        defaultProps: {
          variant: 'outlined',
          size: 'small',
          color: 'inherit',
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiList: {
        defaultProps: {
          disablePadding: true,
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            backgroundColor: 'black',
            color: 'white',
            fontSize: 10,
            padding: 0,
          },
        },
        defaultProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&$disabled': {
              color: themeType === 'light' ? 'black' : 'white',
              opacity: 0.69,
            },
          },
          input: {
            padding: '10.5px 8px',
          },
          multiline: {
            padding: '4px !important',
          },
          adornedEnd: {
            paddingRight: 0,
          },
        },
        defaultProps: {
          margin: 'dense',
          fullWidth: true,
          autoComplete: 'off',
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: 12,
            lineHeight: '16px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            color: themeType === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
          },
          formControl: {
            top: 'auto',
            left: 'auto',
            position: 'static',
            transform: 'none',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          fullWidth: true,
          autoComplete: 'off',
          variant: 'outlined',
        },
      },
      MuiFormControl: {
        styleOverrides: {
          marginDense: {
            marginBottom: 0,
            marginTop: 0,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            lineHeight: '16px',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginRight: 0,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 0,
          },
          sizeSmall: {
            marginTop: 0,
          },
        },
        defaultProps: {
          margin: 'dense',
          variant: 'standard',
        },
      },
      MuiGrid: {
        defaultProps: {
          spacing: 1,
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
        defaultProps: {
          underline: 'none',
        },
      },
      MuiCard: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: `1px solid ${defaultTheme.palette.divider}`,
          },
        },
        defaultProps: {
          size: 'small',
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:last-child .MuiTableCell-root': {
              borderBottom: 0,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: 12,
            lineHeight: '16px',
          },
          head: {
            fontSize: 12,
            lineHeight: '16px',
          },
          sizeSmall: {
            padding: '4px !important',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: 12,
          },
        },
        defaultProps: {
          size: 'small',
          variant: 'outlined',
        },
      },
      MuiCircularProgress: {
        defaultProps: {
          disableShrink: true,
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: '1px solid #d3d4d5',
          },
        },
        defaultProps: {
          elevation: 0,
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 12,
            lineHeight: '16px',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#444444',
            color: 'white',
            border: themeType === 'light' ? '' : '1px solid white',
            maxWidth: 600,
          },
          arrow: {
            color: themeType === 'light' ? '#444444' : 'white',
          },
        },
        defaultProps: {
          TransitionComponent: Zoom,
          arrow: true,
        },
      },
      MuiInputAdornment: {
        defaultProps: {
          position: 'end',
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '8px 24px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            minHeight: '48px !important',
            maxHeight: '48px !important',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '48px !important',
            maxHeight: '48px !important',
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            padding: 12,
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
        },
      },
    },
  });
};

const propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['light', 'dark']),
};

type ThemePropTypes = InferProps<typeof propTypes>;

export default function Theme({ children, type }: ThemePropTypes): JSX.Element {
  const appContext = useAppContext();

  const themeType = useMemo(() => {
    if (type) {
      return type;
    }

    if (appContext?.user?.darkTheme === true) {
      return 'dark';
    }
    if (appContext?.user?.darkTheme === false) {
      return 'light';
    }

    // Otherwise, fallback to their OS/browser preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return prefersDark ? 'dark' : 'light';
  }, [appContext?.user?.darkTheme, type]);

  const theme = useMemo(() => getTheme(themeType as PaletteMode), [themeType]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

Theme.propTypes = propTypes;

Theme.defaultProps = {
  type: null,
};
