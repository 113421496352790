import React from 'react';
import { AppContextContainer } from './app-context';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/dashboard';
import { Amplify } from 'aws-amplify';
import awsConfig from './awsconfig';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { CssBaseline, Typography, Box, IconButton } from '@mui/material';
import Theme from './theme/index';
import colors from './theme/colors.json';
import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Close } from 'mdi-material-ui';

import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsConfig);

function Switch() {
  return (
    <Routes>
      <Route path="/*" element={<Dashboard />} />
    </Routes>
  );
}

// Theme for just AWS-Amplify - distinct from the main MUI theme
const amplifyTheme = {
  name: 'amplify-theme',
  tokens: {
    fonts: {
      default: {
        variable: { value: 'Noto Sans, sans-serif' },
        static: { value: 'Noto Sans, sans-serif' },
      },
    },
    colors: {
      brand: {
        primary: {
          20: { value: colors.green7 },
          60: { value: colors.accessibleGreen },
          80: { value: colors.accessibleGreen },
          90: { value: colors.deloitteGreen },
          100: { value: colors.green7 },
        },
      },
    },
  },
};

// Custom styling for SignIn - Add Header text and center login on page
const components = {
  SignIn: {
    Header() {
      return (
        <Box display="flex" justifyContent="center">
          <Typography variant="h4" style={{ color: colors.accessibleGreen, paddingTop: '20px' }}>
            Sign In
          </Typography>
        </Box>
      );
    },
  },
};

const PREFIX = 'app';

const classes = {
  authenticator: `${PREFIX}-authenticator`,
};
const StyledAuthenticator = styled(Authenticator)(() => ({
  [`&.${classes.authenticator}`]: {
    minHeight: '100vh',
  },
}));

const App = () => {
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: any) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  return (
    <AmplifyProvider theme={amplifyTheme}>
      <StyledAuthenticator hideSignUp components={components} className={classes.authenticator}>
        {() => (
          <AppContextContainer>
            <Theme>
              <CssBaseline />
              <SnackbarProvider
                autoHideDuration={10000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                ref={notistackRef}
                maxSnack={5}
                action={(key) => (
                  <IconButton onClick={onClickDismiss(key)} color="inherit">
                    <Close style={{ fontSize: 14 }} />
                  </IconButton>
                )}
              >
                <Switch />
              </SnackbarProvider>
            </Theme>
          </AppContextContainer>
        )}
      </StyledAuthenticator>
    </AmplifyProvider>
  );
};

export default App;
