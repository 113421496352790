import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Link, Container } from '@mui/material';
import colors from '../theme/colors.json';

const PREFIX = 'footer';

const classes = {
  footer: `${PREFIX}-footer`,
  complianceTxt: `${PREFIX}-complianceTxt`,
  tradeMarkTxt: `${PREFIX}-tradeMarkTxt`,
  linkTxt: `${PREFIX}-linkTxt`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.footer}`]: {
    backgroundColor: colors.black,
    maxWidth: '100%',
    position: 'fixed',
    bottom: 0,
    paddingTop: 10,
  },

  [`& .${classes.complianceTxt}`]: {
    color: colors.white,
  },

  [`& .${classes.tradeMarkTxt}`]: {
    color: colors.white,
  },

  [`& .${classes.linkTxt}`]: {
    color: colors.white,
  },
}));

export default function Footer() {
  const bottomNavMenuItems = [
    {
      label: 'About Deloitte',
      value:
        'https://www2.deloitte.com/us/en/pages/about-deloitte/articles/about-deloitte.html?icid=bottom_about-deloitte',
    },
    {
      label: 'Terms of Use',
      value: 'https://www2.deloitte.com/us/en/footerlinks1/terms-of-use.html?icid=bottom_terms-of-use',
    },
    {
      label: 'Privacy Notice',
      value: 'https://www2.deloitte.com/us/en/footerlinks1/privacy-index.html?icid=bottom_privacy-index',
    },
    {
      label: 'Privacy Shield',
      value: 'https://www2.deloitte.com/us/en/footerlinks1/privacy-shield.html?icid=bottom_privacy-shield',
    },
    { label: 'Cookies', value: 'https://cookienotice.deloitte.com' },
    {
      label: 'Do Not Sell My Personal Information',
      value:
        'https://www2.deloitte.com/us/en/footerlinks1/do-not-sell-my-personal-information.html?icid=bottom_do-not-sell-my-personal-information',
    },
  ];
  return (
    <StyledContainer className={classes.footer}>
      <Grid container direction="row" justifyContent="center">
        {bottomNavMenuItems.map((menuItem, index) => (
          <Grid key={index} item>
            <Box p={2}>
              <Link component="a" underline="hover" className={classes.linkTxt} href={menuItem.value}>
                {menuItem.label}
              </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={8}>
          <Box display="flex" p={1}>
            <Typography className={classes.complianceTxt}>
              Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by
              guarantee (“DTTL”), its network of member firms, and their related entities. DTTL and each of its member
              firms are legally separate and independent entities. DTTL (also referred to as “Deloitte Global”) does not
              provide services to clients. In the United States, Deloitte refers to one or more of the US member firms
              of DTTL, their related entities that operate using the “Deloitte” name in the United States and their
              respective affiliates. Certain services may not be available to attest clients under the rules and
              regulations of public accounting. Please see www.deloitte.com/about to learn more about our global network
              of member firms.
            </Typography>
          </Box>
          <Box display="flex" p={1}>
            <Link component="a" underline="hover" className={classes.linkTxt} href="/attribution-notice.html">
              Attribution Notice
            </Link>
          </Box>
          <Box display="flex" p={1} mb={2}>
            <Typography className={classes.tradeMarkTxt}>
              Copyright © 2021 Deloitte Development LLC. All rights reserved.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
