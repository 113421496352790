import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { Container } from '@mui/material';

const PREFIX = 'index';

const classes = {
  entireSite: `${PREFIX}-entireSite`,
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.entireSite}`]: {
    overflow: 'auto',
  },

  [`& .${classes.container}`]: {
    maxWidth: '100%',
    height: '100%',
    marginBottom: 230,
  },
}));

interface ILayoutProps {
  children: ReactElement;
}

export default function Layout({ children }: ILayoutProps) {
  return (
    <Root>
      <Navbar />
      <Container className={classes.container}>{children}</Container>
      <Footer />
    </Root>
  );
}
