import { Auth } from 'aws-amplify';

export async function getUserToken(): Promise<string> {
  const res = await Auth.currentSession();

  return res.getIdToken().getJwtToken();
}

export async function getUploadPath(): Promise<string> {
  const res = await Auth.currentSession();
  const timeStamp = new Date(Date.now()).toISOString().replaceAll(':', '.');

  const token = res.getIdToken().decodePayload();

  const path = token['cognito:groups'][0].replaceAll('-', '/');
  const userName = token.email.slice(0, token.email.indexOf('@'));

  return `${path}/${userName}-${timeStamp}`;
}
