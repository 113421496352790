export const MIN_FILE_CHUNK_SIZE = 5 * 1024 * 1024;
export const Mb = 1024 * 1024;
export const Gb = 1024 * Mb;

interface FileUploadCache {
  uploadId: string;
  parts: FileUploadPart[];
  chunkSize: number;
  path: string;
}

interface FileUploadPart {
  partNumber: number;
  etag: string;
  completed: boolean;
}

export class FileUpload {
  public uploadId = '';

  public parts: FileUploadPart[] = [];

  public chunkSize = 0;

  public uploaded = false;

  public path = '';

  public errorMessage = '';

  private readonly file: File;

  // Never round up to 100 - only show 100% when all parts are completed
  public get progress(): number {
    const partsCompleted = this.parts.filter((part) => part.completed).length;
    return partsCompleted > 0 && partsCompleted === this.parts.length
      ? 100
      : Math.min(Math.round((partsCompleted / this.parts.length) * 100 || 0), 99);
  }

  public get fileName(): string {
    return this.file.name;
  }

  public get fileType(): string {
    return this.file.type;
  }

  public get incompleteParts(): FileUploadPart[] {
    return this.parts.filter((part) => !part.completed);
  }

  public constructor(file: File) {
    this.file = file;
  }

  public getFilePartSlice(partNumber: number): Blob {
    if (this.file.size < this.chunkSize) {
      return this.file.slice(0, this.file.size);
    }

    return this.file.slice((partNumber - 1) * this.chunkSize, partNumber * this.chunkSize);
  }

  public async completePart(partNumber: number, etag: string): Promise<void> {
    // eslint-disable-next-line
    const partIndex = this.parts.findIndex((part) => part.partNumber == partNumber);
    if (partIndex > -1) {
      this.parts[partIndex].completed = true;
      this.parts[partIndex].etag = etag;
      await this.saveToFileUploadCache();
    }
    this.onPartsUpdate();
  }

  public async completeUpload(): Promise<void> {
    this.uploaded = true;
    const fileId = await this.getFileSignature();
    localStorage.removeItem(fileId);
  }

  public async checkResume(): Promise<void> {
    const cachedFileUpload = await this.getFileUploadFromCache();

    if (cachedFileUpload) {
      this.uploadId = cachedFileUpload.uploadId;
      this.parts = cachedFileUpload.parts;
      this.chunkSize = cachedFileUpload.chunkSize;
      this.path = cachedFileUpload.path;

      console.log(
        `Resuming file upload for ${this.path}/${this.fileName} with ${this.incompleteParts.length} parts remaining`,
      );

      this.onPartsUpdate();
    } else {
      this.calculateFileParts();
    }
  }

  public onPartsUpdate(): void {}

  public onErrorUpdate(): void {}

  // Use smallest part size possible that is at least minimum size and
  // Under maximum total number of parts allowable
  // Seems to be 1024 is the largest number of parts before we experience failures
  private calculateFileParts(chunkSize = MIN_FILE_CHUNK_SIZE): void {
    chunkSize = Math.max(Math.ceil(this.file.size / 1024), MIN_FILE_CHUNK_SIZE);
    const totalParts = Math.ceil(this.file.size / chunkSize);

    return this.commitParts({ chunkSize, totalParts });
  }

  private commitParts({ chunkSize, totalParts }: { chunkSize: number; totalParts: number }): void {
    this.chunkSize = chunkSize;

    const parts: FileUploadPart[] = [];
    for (let i = 0; i < totalParts; i++) {
      parts.push({ partNumber: i + 1, completed: false, etag: '' });
    }

    this.parts = parts;
  }

  private async getFileSignature(): Promise<string> {
    const fileSignature = this.fileName + this.file.size + this.file.type + this.file.lastModified;
    const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(fileSignature));
    const hashArray = Array.from(new Uint8Array(hash));

    return hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
  }

  public async saveToFileUploadCache(): Promise<void> {
    const item: FileUploadCache = {
      uploadId: this.uploadId,
      chunkSize: this.chunkSize,
      parts: this.parts,
      path: this.path,
    };

    const fileId = await this.getFileSignature();
    return localStorage.setItem(fileId, JSON.stringify(item));
  }

  private async getFileUploadFromCache(): Promise<FileUploadCache | null> {
    const fileId = await this.getFileSignature();
    if (!fileId) {
      console.error('unable to generate file signature for file ' + this.fileName);
      return null;
    }

    const item = localStorage.getItem(fileId);

    return item ? JSON.parse(item) : null;
  }
}
