import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

const AppContext = React.createContext<{
  currentForms: any;
  setCurrentForms: any;
  loginRedirect: string;
  setLoginRedirect: any;
} | null>(null);

export default function useAppContext(): any {
  const appContext = useContext(AppContext);
  return appContext;
}

export function AppContextContainer({ children }: any) {
  // used for showing the route leaving guard for potentially nested forms
  const [currentForms, setCurrentForms] = useState(new Map());
  const [loginRedirect, setLoginRedirect] = useState('/');

  const defaultContext = {
    currentForms,
    setCurrentForms,
    loginRedirect,
    setLoginRedirect,
  };

  return <AppContext.Provider value={defaultContext}>{children}</AppContext.Provider>;
}

AppContextContainer.propTypes = {
  children: PropTypes.any,
};
